import {
  DashboardOutlined,
  DatabaseOutlined,
  BorderInnerOutlined,
  FundProjectionScreenOutlined,
  CheckCircleOutlined,
  BuildOutlined,
  HistoryOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useMenuSkid from "components/BaseLayout/sections/skid-item";

const useMtnMenu = () => {
  const { lastLogin } = useSelector((state: any) => state.auth);
  const skidItem = useMenuSkid() ?? [];
  const { poId } = useParams();

  const mtnMenu = [
    {
      key: "/mtn",
      icon: <DashboardOutlined />,
      label: "Dashboard",
    },
    {
      key: "/profile",
      icon: <DashboardOutlined />,
      label: "Dashboard",
    },
    {
      key: "/mtn/parts",
      icon: <BuildOutlined />,
      label: "Part",
      children: [
        {
          key: "/mtn/part",
          label: "Data",
        },
        {
          key: "/mtn/part/in",
          label: "In",
        },
      ],
    },
    {
      key: "/mtn/master-data",
      icon: <DatabaseOutlined />,
      label: "Master Data",
      children: [
        {
          key: "/mtn/master-data/rank",
          label: "Category",
        },
        {
          key: "/mtn/master-data/store",
          label: "Store",
        },
        {
          key: "/mtn/master-data/supplier",
          label: "Supplier",
        },
        {
          key: "/mtn/master-data/line",
          label: "Line",
        },
        {
          key: "/mtn/master-data/unit",
          label: "Unit",
        },
        {
          key: "/mtn/master-data/maker",
          label: "Maker",
        },
      ],
    },
    {
      key: "/mtn/order-history",
      icon: <HistoryOutlined />,
      label: "Order History",
      children: [
        {
          key: "/mtn/order-history/purchase",
          label: "Purchase Order",
        },
        {
          key: "/mtn/order-history/repair",
          label: "Repair Order",
        },
      ],
    },
    {
      key: "/mtn/approval",
      icon: <CheckCircleOutlined />,
      label: "Approval",
    },
    {
      key: "/mtn/Inventory",
      icon: <BorderInnerOutlined />,
      label: "Inventory",
      children: [
        {
          key: "/mtn/inventory/process",
          label: "Process",
        },
        {
          key: "/mtn/inventory/recap",
          label: "Recap",
        },
      ],
    },
    {
      key: "/mtn/report",
      icon: <FundProjectionScreenOutlined />,
      label: "Report",
    },

    {
      key: "/mtn/inventory/compare",
      label: "Compare",
    },
    {
      key: `/mtn/part/in/po/${poId}/detail`,
      label: "Detail PO",
    },
    {
      key: `/mtn/order-history/purchase/detail/${poId}`,
      label: "Detail PO",
    },
  ];

  const filterItem = () => {
    const role = lastLogin?.role?.id;
    if (role === 1) {
      return mtnMenu;
    } else if (role === 4) {
      return mtnMenu;
    } else if (role === 11) {
      const roleEleven = mtnMenu
        ?.filter((item) => ["Part", "Order History"].includes(item.label))
        ?.map((item) => {
          return {
            ...item,
            children: item?.children?.filter(
              (item) => item.key !== "/mtn/part/in"
            ),
          };
        });
      return roleEleven;
    } else {
      return skidItem;
    }
  };

  const mtnItem = filterItem();

  return mtnItem;
};

export default useMtnMenu;
