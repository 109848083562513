import { useState } from "react";
import { Card, Row, Button, Pagination, Table, Tooltip, Modal } from "antd";
import { AppDispatch } from "stores";
import { useSelector, useDispatch } from "react-redux";
import { getLimitPart } from "stores/order-history/purchase/actions/purchase";
import { UnorderedListOutlined, PlusSquareOutlined } from "@ant-design/icons";
import StatusRepairDialog from "pages/mtn/order-history/purchase/sections/table-pagination/dialog/status";
import { setPageSize, setCart } from "stores/order-history/purchase";
import DetailRepairPartDialog from "pages/mtn/order-history/purchase/sections/table-pagination/dialog/detail";

const TablePaginationStockLimit = () => {
  const [currentRecord, setCurrentRecord] = useState<any>({});
  const [modal, contextHolder] = Modal.useModal();
  const {
    isLoadingData,
    currentPage,
    totalData,
    search,
    pageSize,
    datas,
    carts,
  } = useSelector((state: any) => state.purchase);

  const newDatas = datas?.map((item: any, index: number) => {
    return {
      ...item,
      no: pageSize * (currentPage - 1) + index + 1,
    };
  });

  const dispatch = useDispatch<AppDispatch>();

  const handlePaginate = async (page: number, pageSize: number) => {
    dispatch(setPageSize(pageSize));
    const params: any = {
      page: page,
      per_page: pageSize,
      keyword: search,
      // status_id: activeTab,
    };

    if (search) {
      params.keyword = search;
    }

    await dispatch(getLimitPart(params)).unwrap();
  };

  const [toggleDetail, setToggleDetail] = useState(false);

  const columns: any = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: 60,
      align: "center",
    },
    {
      title: "ID Part",
      dataIndex: "part_number",
      key: "part_number",
      width: 200,
    },
    {
      title: "Name",
      dataIndex: "part_name",
      key: "name",
      width: 150,
    },
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
      width: 150,
    },
    {
      title: "Quantity",
      dataIndex: "qty",
      key: "qty",
      width: 100,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: 150,
    },
    {
      title: "Maker",
      dataIndex: "maker_name",
      key: "maker_name",
      width: 150,
      render: (r: any) => r || "-",
    },
    {
      title: "Supplier",
      dataIndex: "supplier_name",
      key: "supplier_name",
      width: 150,
      render: (r: any) => r || "-",
    },
    {
      title: "Store",
      dataIndex: "store_code",
      key: "store",
      width: 150,
    },
    {
      title: "Options",
      key: "options",
      width: 100,
      fixed: "right",
      render: (dataRecord: any) => {
        return (
          <Row justify="space-between">
            <Tooltip title="Detail">
              <Button
                onClick={() => {
                  setCurrentRecord(dataRecord);
                  setToggleDetail(true);
                }}
                type="primary"
                shape="circle"
                icon={<UnorderedListOutlined />}
              />
            </Tooltip>
            <Tooltip
              title={
                carts.findIndex((arr: any) => arr.id === dataRecord.id) !== -1
                  ? "Part in Cart"
                  : "Add to Cart"
              }
            >
              <Button
                disabled={
                  carts.findIndex((arr: any) => arr.id === dataRecord.id) !== -1
                }
                onClick={() => {
                  dispatch(
                    setCart({
                      ...dataRecord,
                      qty: 1,
                      stock: dataRecord?.qty,
                      remarks: "",
                    })
                  );
                }}
                type="primary"
                shape="circle"
                icon={<PlusSquareOutlined />}
              />
            </Tooltip>
          </Row>
        );
      },
    },
  ];

  return (
    <Card>
      {contextHolder}
      <StatusRepairDialog dataRecord={currentRecord} />
      <DetailRepairPartDialog
        toggleDetail={toggleDetail}
        setToggleDetail={setToggleDetail}
        dataRecord={currentRecord}
      />

      <Table
        // rowKey={(record: any) => record.id}
        loading={isLoadingData}
        columns={columns}
        dataSource={newDatas}
        pagination={false}
        scroll={{ x: "calc(700px + 50%)" }}
      />
      <Row className="mt-5" justify="end">
        <Pagination
          current={currentPage}
          total={totalData}
          showSizeChanger
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          onChange={(page, pageSize) => handlePaginate(page, pageSize)}
        />
      </Row>
    </Card>
  );
};

export default TablePaginationStockLimit;
