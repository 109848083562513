import { useState, useRef, useEffect } from 'react'
import { Modal, Button, Row } from 'antd'
import * as XLSX from 'xlsx'
import dayjs from 'dayjs'
import minMax from 'dayjs/plugin/minMax'
import { useSelector } from 'react-redux'
import { useReactToPrint } from 'react-to-print'
import InventoryService from 'services/skids/InventoryService'

import zeroSize from 'utils/zeroSize'
import 'assets/styles/table_report.css'

dayjs.extend(minMax)

const Preview = () => {
  const [modal, setModal] = useState(false)
  const [previews, setPreviews] = useState([])
  const componentRef: any = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  })

  const { search, thick, width, length1, length2, date } = useSelector(
    (state: any) => state.adjustInv
  )

  const dateParse: any = (dateNow: any) =>
    dateNow
      ? dayjs(dateNow).format('DD-MM-YYYY')
      : dayjs(new Date()).format('DD-MM-YYYY')
  const timeParse = dayjs(new Date()).format('HH:mm:ss')

  const periode = () => {
    if (date) {
      return dateParse(date[0]) + ' - ' + dateParse(date[1])
    } else if (previews.length > 0) {
      const startPeriode: any = dayjs.min(
        previews.map((item: any) => dayjs(item.create_time))
      )
      const endPeriode: any = dayjs.max(
        previews.map((item: any) => dayjs(item.create_time))
      )
      return dateParse(startPeriode) + ' - ' + dateParse(endPeriode)
    } else {
      return '-'
    }
  }

  const accumulate = (val: any, type: string) => {
    return val
      ?.map((item: any) => {
        if (type === 'actual_system') {
          return parseInt(item?.actual_system)
        } else if (type === 'in') {
          return parseInt(item?.qty_in)
        } else if (type === 'out') {
          return parseInt(item?.qty_out)
        } else if (type === 'qty_scanned') {
          return parseInt(item?.qty_scanned)
        } else {
          return 0
        }
      })
      .reduce((acc: any, current: any) => acc + current, 0)
  }

  const total = {
    actual_system: accumulate(previews, 'actual_system'),
    in: accumulate(previews, 'in'),
    out: accumulate(previews, 'out'),
    qty_scanned: accumulate(previews, 'qty_scanned')
  }

  const falseValue = (str: any) => {
    if (str === '' || str === '0.00' || str === '0') {
      return ''
    } else {
      return parseFloat(str).toFixed(2)
    }
  }

  const getPreview = async () => {
    const params: any = {}
    if (search) {
      params.code_skid = search
    }
    if (date && date.length > 0) {
      params.start_date = dayjs(date[0]).format('YYYY-MM-DD')
      params.end_date = dayjs(date[1]).format('YYYY-MM-DD')
    }

    // if (selectCustomer.length > 0) {
    //   params.customer = selectCustomer.toString();
    // }

    if (falseValue(thick)) {
      params.thick = falseValue(thick)
    }

    if (falseValue(width)) {
      params.width = falseValue(width)
    }

    if (falseValue(length1)) {
      params.length1 = falseValue(length1)
    }

    if (falseValue(length2)) {
      params.length2 = falseValue(length2)
    }

    const { data } = await InventoryService.previewInventoryV2(params)
    setPreviews(data)
  }

  useEffect(() => {
    if (modal) {
      getPreview()
    }
  }, [modal])

  const generateXls = () => {
    // Acquire Data (reference to the HTML table)
    const table_elt = document.getElementById('tableListIventtory')
    console.log(table_elt)

    // Extract Data (create a workbook object from the table)
    const workbook = XLSX.utils.table_to_book(table_elt)

    // Package and Release Data (`writeFile` tries to write and save an XLSB file)
    XLSX.writeFile(workbook, 'Report_Summary.xlsx')
  }

  const handlePrintProcess = async () => {
    try {
      handlePrint()
    } catch (err) {
      console.log(err)
    }
  }

  const newDatas = previews?.map((item: any, index: number) => ({
    ...item,
    no: index + 1,
    customer: item?.customer,
    no_label: item?.code_skid,
    thick: zeroSize(item?.thick),
    width: zeroSize(item?.width),
    length1: zeroSize(item?.length1),
    length2: zeroSize(item?.length2),
    status: item?.status ? item.status.toUpperCase() : '-'
  }))

  return (
    <>
      <Button block type="primary" onClick={() => setModal(true)}>
        Preview
      </Button>
      <Modal
        title="Preview List Inventory"
        centered
        open={modal}
        onCancel={() => setModal(false)}
        width={'80%'}
        footer={[
          <Button key="back" onClick={() => setModal(false)}>
            Back
          </Button>,
          <Button key="submit" type="primary" onClick={handlePrintProcess}>
            Print
          </Button>,
          <Button key="download" onClick={() => generateXls()} type="primary">
            Download
          </Button>
        ]}
      >
        <Row ref={componentRef} justify="center">
          <table className="table-width" id="tableListIventtory">
            <tr>
              <td colSpan={10}>PT. Steel Center Indonesia</td>
            </tr>
            <tr>
              <td colSpan={10}>List Inventoty In-Out Skid Besi</td>
            </tr>
            <tr>
              <td colSpan={10}>Date {dateParse('')}</td>
            </tr>
            <tr>
              <td colSpan={10}>Time {timeParse}</td>
            </tr>
            <tr>
              <td colSpan={10}>Periode {periode()}</td>
            </tr>
            <br />
            {/* <tr>
              <td colSpan={6}></td>
              <td className="col-total col-total-start">Total</td>
              <td className="col-total">{total?.actual_system}</td>
              <td className="col-total">{total?.in}</td>
              <td className="col-total col-total-end">{total?.out}</td>
              <td className="col-total col-total-end">{total?.qty_scanned}</td>
            </tr> */}
            <tr>
              <th className="active-border col-no" rowSpan={2}>
                No
              </th>
              <th className="active-border col-customer" rowSpan={2}>
                Customer
              </th>
              <th className="active-border col-label" rowSpan={2}>
                Code SKID
              </th>
              <th className="active-border col-skid-size" colSpan={4}>
                Skid Size
              </th>
              <th className="active-border col-skid-status" rowSpan={2}>
                Status
              </th>
              <th className="active-border col-skid-asset" rowSpan={2}>
                Remarks
              </th>
              {/* <th className="active-border col-skid-asset" rowSpan={2}>
                Actual System
              </th>
              <th className="active-border col-skid-in" rowSpan={2}>
                In
              </th>
              <th className=" active-border col-skid-out" rowSpan={2}>
                Out
              </th>
              <th className="active-border col-skid-out" rowSpan={2}>
                Scanned
              </th> */}
              <th className="active-border col-skid-out" rowSpan={2}>
                Last Inventory
              </th>
            </tr>
            <tr className="active-border">
              <th className="active-border col-size">T</th>
              <th className="active-border col-size">W</th>
              <th className="active-border col-size">L1</th>
              <th className="active-border col-size">L2</th>
            </tr>
            {newDatas?.map((item: any) => (
              <tr key={item?.no} className="active-border text-center">
                <td className="active-border">{item?.no}</td>
                <td className="active-border">{item?.customer}</td>
                <td className="active-border">{item?.no_label}</td>
                <td className="active-border" data-t="n" data-z="0.00">
                  {item?.thick}
                </td>
                <td className="active-border" data-t="n" data-z="0.00">
                  {item?.width}
                </td>
                <td className="active-border" data-t="n" data-z="0.00">
                  {item?.length1}
                </td>
                <td className="active-border" data-t="n" data-z="0.00">
                  {item?.length2}
                </td>
                <td className="active-border">{item?.status}</td>
                <td className="active-border">{item?.remarks}</td>

                {/* <td className="active-border">{item?.actual_system}</td>
                <td className="active-border">{item?.qty_in}</td>
                <td className="active-border">{item?.qty_out}</td>
                <td className="active-border">{item?.qty_scanned}</td> */}
                <td className="active-border">
                  {item.last_inventory
                    ? item.last_inventory === '-'
                      ? '-'
                      : dayjs(item.last_inventory).format('DD/MM/YYYY HH:mm')
                    : 'No'}
                </td>
              </tr>
            ))}
          </table>
        </Row>
      </Modal>
    </>
  )
}

export default Preview
