import { useEffect } from "react";
import { AppDispatch } from "stores";
import { useSelector, useDispatch } from "react-redux";
import {
  get,
  getLimitPart,
} from "stores/order-history/purchase/actions/purchase";
import {
  setOptionTabs,
  setSearch,
  setPartId,
  // setActiveTab,
  setPageSize,
  resetRepairPart,
} from "stores/order-history/purchase";
import TablePagination from "pages/mtn/order-history/purchase/sections/table-pagination";
import TablePaginationStockLimit from "pages/mtn/order-history/purchase/sections/table-pagination/stock-limit";
import PurchaseService from "services/mtns/order-history/PurchaseService";
import useWatch from "hooks/useWatch";

const Tab = () => {
  const { activeTab } = useSelector((state: any) => state.purchase);
  const dispatch = useDispatch<AppDispatch>();

  const initialComponent = async () => {
    const { data } = await PurchaseService.getFilterPurchaseStatus();
    const dataTabs = [
      { key: 1, label: "Stock Limit" },
      ...data?.data?.map((item: any) => ({
        key: item.id,
        label: item.status_name === "Waiting" ? "Incomplete": item.status_name,
      })),
    ];
    dispatch(setOptionTabs(dataTabs));
    const params: any = {
      page: 1,
      per_page: 10,
    };

    await dispatch(getLimitPart(params)).unwrap();
  };

  useEffect(() => {
    initialComponent();
    return () => {
      dispatch(resetRepairPart());
    };
  }, []);

  //Change Tab
  useWatch(() => {
    dispatch(setSearch(""));
    dispatch(setPartId(""));
    dispatch(setPageSize(10));

    if (activeTab !== 1) {
      const params: any = {
        page: 1,
        per_page: 10,
        status_id: activeTab,
      };
      dispatch(get(params)).unwrap();
    } else {
      const params: any = {
        page: 1,
        per_page: 10,
      };

      dispatch(getLimitPart(params)).unwrap();
    }
  }, [activeTab]);

  return activeTab === 1 ? <TablePaginationStockLimit /> : <TablePagination />;
};

export default Tab;
