import Repair from "pages/mtn/order-history/repair";
import Purchase from "pages/mtn/order-history/purchase";
import DetailPO from "pages/mtn/order-history/purchase/detail";

const orderHistory = [
  {
    path: "/mtn/order-history/repair",
    element: <Repair />,
  },
  {
    path: "/mtn/order-history/purchase",
    element: <Purchase />,
  },
  {
    path: "/mtn/order-history/purchase/detail/:poId",
    element: <DetailPO />,
  },
];

export default orderHistory;
