import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { AppDispatch } from "stores";
import { toggleUpdateDialog } from "stores/part";
import { get, update } from "stores/part/actions/part";
import useDialogPart from "pages/mtn/part/sections/hooks/useDialogPart";
import PartService from "services/mtns/part/PartService";
import fileToBase64 from "utils/fileToBase64";

const useDialogUpdate = (dataRecord: any) => {
  const {
    partOption,
    form,
    openModalUpdate,
    setFileImg,
    setImgBase64,
    fileImg,
    imgBase64,
    imgRef,
    handleRefImg,
    handleUpload,
    openModalCreate,
  } = useDialogPart();

  const { dialogUpdate, isLoadingUpdate, pageSize } = useSelector(
    (state: any) => state.part
  );
  const dispatch = useDispatch<AppDispatch>();

  const handleCancel = () => {
    form.resetFields();
    dispatch(toggleUpdateDialog(false));
    setFileImg(null);
    setImgBase64("");
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const params = {
        id: dataRecord?.id,
        body: { ...values, file: fileImg, one_used: values.one_used ? 1 : 0 },
      };

      await dispatch(update(params)).unwrap();
      const paramsGet = {
        page: 1,
        per_page: pageSize,
      };
      await dispatch(get(paramsGet)).unwrap();
      form.resetFields();
    } catch (err) {
      console.log(err);
    }
  };

  const setForm = async () => {
    form.setFieldsValue({
      part_name: dataRecord?.part_name,
      store_id: dataRecord?.store_id,
      qty: dataRecord?.qty,
      unit_id: dataRecord?.unit_id,
      remarks: dataRecord?.remarks,
      stock_limit: dataRecord?.stock_limit,
      line_id: dataRecord?.line_id,
      type: dataRecord?.type,
      maker_id: dataRecord?.maker_id,
      I_L: dataRecord?.I_L,
      supplier_id: dataRecord?.supplier_id,
      price: dataRecord?.price,
      size: dataRecord?.size,
      stock_max: dataRecord?.stock_max,
      one_used: dataRecord?.one_used ? true : false,
      range: dataRecord?.range,
    });

    const res: any = await PartService.getPartImage({}, dataRecord?.filename);
    const base64file = await fileToBase64(res?.data);
    setImgBase64(base64file);
  };

  useEffect(() => {
    if (dialogUpdate) {
      openModalUpdate();
      setForm();
    }
  }, [dialogUpdate]);

  return {
    dialogUpdate,
    partOption,
    isLoadingUpdate,
    form,
    handleSubmit,
    handleCancel,
    imgBase64,
    imgRef,
    handleRefImg,
    handleUpload,
  };
};

export default useDialogUpdate;
