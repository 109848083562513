import { Modal, Button, Form, Input, Col, Row, Select } from "antd";
import useDialogUpdate from "pages/mtn/order-history/purchase/sections/table-pagination/dialog/useDialogUpdate";

const { TextArea } = Input;

const UpdatePart = ({ dataRecord }: any) => {
  const {
    dialogStatus,
    partOption,
    isLoadingUpdate,
    form,
    handleSubmitStatus,
    handleCancel,
  } = useDialogUpdate(dataRecord);

  return (
    <>
      {/* dialog element */}
      <Modal
        forceRender
        // width={800}
        open={dialogStatus}
        title="Change Status"
        onCancel={() => handleCancel()}
        footer={[
          <Button onClick={() => handleCancel()} key="back">
            Cancel
          </Button>,
          <Button
            loading={isLoadingUpdate}
            onClick={handleSubmitStatus}
            key="submit"
            type="primary"
          >
            Save
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical" name="userForm">
          <Row gutter={10}>
            <Col span={24}>
              <Form.Item
                name="status_id"
                label="Status"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) =>
                    (option?.label.toLowerCase() ?? "").includes(
                      input.toLowerCase()
                    )
                  }
                  filterSort={(optionA: any, optionB: any) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={partOption?.statuses?.map((item: any) => ({
                    ...item,
                    label: item.label === "Waiting" ? "Incomplete" : item.label,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="remarks"
                label="Remarks"
                rules={[{ required: true }]}
              >
                <TextArea rows={2} placeholder="Type here..." />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default UpdatePart;
